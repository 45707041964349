<!-- System: STA
    Purpose: This compoment will display add credit
              card available cards.
-->

<template>
  <v-col cols="12" class="cards-grid pt-1">
    <v-row class="payment-methods ml-0 mr-0 d-flex">
      <!-- Card To Add New Credit Card -->
      <v-card
        class="mb-2 col-sm-4 p-0 add-card mr-5"
        img-alt="Image"
        img-top
        max-width="344"
        tag="article"
        color="blue lighten-5"
        @click="openCreditCardModal"
      >
        <div class="card-text text-center mt-3 align-items-center">
          <img src="../../assets/images/dashboard_icons/plus_icon_round.svg" />
          <p class="mt-2">Add new Default Credit Card</p>
        </div>
      </v-card>

      <!-- All Credit Card -->
      <v-card
        :class="card.brand"
        :key="card.id"
        class="mb-2 p-0 col-md-4 credit-card mr-5"
        max-width="344"
        v-for="card in billingCards"
      >
        <div
          :class="card.brand"
          class="card-title text-center text-capitalize font-weight-bold text-white"
        >
          <img style="width:100px;"
          height="50px"
            src="../../assets/images/credit_cards/american_express.png"
            v-if="card.brand === 'amex'"
          />
          <img
            src="../../assets/images/credit_cards/capital_one.svg"
            v-else-if="card.brand === 'capital_one'"
          />
          <img
            src="../../assets/images/credit_cards/citi.svg"
            v-else-if="card.brand === 'citi'"
          />
          <img
            height="50px"
            :width="$vuetify.breakpoint.mdAndDown ? '190px' : '250px'"
            src="../../assets/images/credit_cards/diners_club.svg"
            v-else-if="card.brand === 'diners'"
          />
          <img
            height="50px"
            :width="$vuetify.breakpoint.mdAndDown ? '190px' : '250px'"
            src="../../assets/images/credit_cards/discover.svg"
            v-else-if="card.brand === 'discover'"
          />
          <img
            src="../../assets/images/credit_cards/jcb.svg"
            v-else-if="card.brand === 'jcb'"
          />
          <img
            src="../../assets/images/credit_cards/master.svg"
            v-else-if="card.brand === 'mastercard'"
          />
          <img
            height="50px"
            :width="$vuetify.breakpoint.mdAndDown ? '190px' : '250px'"
            src="../../assets/images/credit_cards/union_pay.svg"
            v-else-if="card.brand === 'unionpay'"
          />
          <img
            height="50px"
            src="../../assets/images/credit_cards/visa.svg"
            v-else-if="card.brand === 'visa'"
          />
          <img src="../../assets/images/credit_cards/unknown_card.svg" v-else />
        </div>

        <div class="card-text text-center p-2 pt-3">
          <div class="card-number">
            <span class="">**** **** **** </span>{{ card.last4 }}
          </div>
          <div class="card-date text-muted">{{ card.created_at }}</div>
        </div>
        <div class="card-settings">
          <!-- Make Default Card Button -->
          <div class="default" v-if="card.default">DEFAULT</div>
          <div
            @click="make_default(card.id)"
            class="make-default hand"
            v-if="!card.default"
          >
            Make Default
          </div>
          <!-- Delete Card Button -->
          <div class="settings-right" v-if="!card.default">
            <div class="edit-card">
              <v-icon
                style="color: white"
                dense
                @click="(cardToRemove = card.id), (removeCardModal = true)"
                >mdi-delete-forever</v-icon
              >
            </div>
          </div>
        </div>
      </v-card>
    </v-row>
    <!-- Dialog Box For Remove Selected Member  -->
    <v-dialog
      v-model="removeCardModal"
      width="500"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title class="headline red lighten-2">
          Are you sure?
        </v-card-title>
        <v-card-text class="mt-4">
          <h4>You want to delete this card.</h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined text @click="removeCardModal = false">
            Cancel
          </v-btn>
          <v-btn
            color="error"
            outlined
            text
            @click="(removeCardModal = false), removeCard()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CreditCard",
  props: ["billingCards"],
  data() {
    return {
      default_card_id: null,
      cardToRemove: "",
      removeCardModal: false,
    };
  },
  computed: mapState("custom", ["selected_company", "company_payments_cards"]),
  methods: {
    /**
     * This function is resposible for
     * sending an emit to credit card modal
     * component to add new card
     */
    openCreditCardModal() {
      this.$root.$emit("add-new-card");
    },
    /**
     * This function is resposible for
     * removing credit card
     */
    removeCard() {
      let card_id = this.cardToRemove;
      let data = {
        company_id: this.$route.params.id,
        card_id,
      };
      this.$store.commit("custom/toggle_loader", true);
      this.$store
        .dispatch("custom/remove_card", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error === false) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
            this.$store.dispatch("custom/company_payments_cards", {
              company_id: data.company_id,
            });
          }
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
          if (error.response.data.error === true) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.error_msg,
              snackbarColor: "red",
            });
          }
        });
    },
    /**
     * This function is resposible for
     * making credit card default
     */
    make_default(card_id) {
      let data = {
        company_id: this.$route.params.id,
        card_id,
      };
      this.$store.commit("custom/toggle_loader", true);
      this.$store
        .dispatch("custom/make_default_card", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error === false) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
            this.$store.dispatch("custom/company_payments_cards", {
              company_id: data.company_id,
            });
          }
        })
        .catch(() => {
          this.$store.commit("custom/toggle_loader", false);
        });
    },
  },
};
</script>

<style scoped>
.add-card {
  cursor: pointer;
}

.credit-card.visa {
  background: white;
}

.credit-card.mastercard {
  background:white;
}

.credit-card.maestro {
  background: white;
}

.credit-card.amex {
  background: white;
}

.card-overlay .card-name {
  width: 45%;
}

.card-overlay .card-date {
  width: 40%;
}

.card-overlay .card-cvv {
  width: 20%;
}

.card-dropdown {
  position: absolute;
  top: 5px;
  right: 38px;
}

.card-settings {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  align-items: flex-start;
  cursor: pointer;
}

.edit-card,
.make-default {
  display: none;
}

.credit-card:hover .edit-card,
.credit-card:hover .make-default {
  display: block;
}

.credit-card img {
  margin: 0 auto;
}

.default,
.make-default {
  background: rgb(25, 74, 158);
  padding: 2px 10px;
  margin: 1%;
  border-radius: 0px;
  font-size: 12px;
  color: #fff;
}

.add-card .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.credit-card {
  border: 0;
}

.credit-card .card-body {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 3px;
}

.row.payment-methods {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-gap: 16px;
  row-gap: 20px;
}

.credit-card.visa .card-img-top {
  /*width: 100px;*/
  background-size: 100%;
  height: 100px;
  background-color: lightblue;
  padding: 20px 70px;
}

.card-title {
  background-color: rgb(38, 98, 214);
  font-size: 30px;
  padding: 25px 15px 15px 15px;
}

.card-title.mastercard {
  background-color: rgb(43, 0, 111);
}

.cards-grid {
  max-height: 350px;
  overflow: scroll;
}

.payment-methods .add-card .card-body {
  border: dashed 2px #cbdaf2;
  border-radius: 3px;
}

.payment-methods .add-card .card-body:hover {
  cursor: pointer !important;
  transform: scale(1.01);
}

@media (max-width: 992px) {
  .row.payment-methods {
    grid-template-columns: 50% 50%;
  }
}

@media (max-width: 767px) {
  .row.payment-methods {
    grid-template-columns: 100%;
  }
}

@media (max-width: 319px) {
  input.InputElement.is-empty.Input.Input--empty,
  input.InputElement.is-invalid.Input {
    padding-right: 63px !important;
  }
}

.card {
  border: unset !important;
  background-color: rgba(240, 248, 255, 0.93);
}

.align-items-center {
  color: #a2b7d9;
  font-size: 13px;
  padding-top: 10px;
}
</style>
